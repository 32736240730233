import styled, { css } from 'styled-components';
type StyledProps = {
  isSquareFormat?: boolean;
};

export const StyledVideo = styled.video<StyledProps>`
  ${({ isSquareFormat }) => css`
    width: 100%;

    ${isSquareFormat &&
    `aspect-ratio: 1/1;
       object-fit: cover;`}
  `}
`;
