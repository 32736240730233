import { useEffect, useRef, useState } from 'react';
import { useInView as useInViewFromIntersectionObserver } from 'react-intersection-observer';
import { useTrackingContext } from '@/contexts/TrackingContext';
import { VideoEventProps, sendInteractionVideo } from '@/helpers/analytics';
import { useInView } from '@/hooks/useInView';
import { StyledVideo } from './HeroVideo.styled';

type HeroVideoProps = {
  srcSet: string[];
  poster?: string;
  loop?: boolean;
  controls?: boolean;
  autoPlay?: boolean;
  attribute?: string;
  component?: string;
  microComponent?: string;
  homePosition?: string;
  isSquareFormat?: boolean;
  onPlay?: () => void;
  onPause?: () => void;
};

const HeroVideo = ({
  srcSet,
  poster,
  loop = false,
  autoPlay = false,
  controls = false,
  attribute,
  component,
  microComponent,
  homePosition,
  isSquareFormat,
  onPlay,
  onPause,
}: HeroVideoProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [firstPlay, setFirstPlay] = useState(true);
  const [firstPause, setFirstPause] = useState(true);
  const [firstSoundClick, setFirstSoundClick] = useState(true);
  const [ref, isVisible] = useInView<HTMLDivElement>(
    useInViewFromIntersectionObserver,
  )({
    intersectionOptions: {
      triggerOnce: true,
      threshold: 0,
      initialInView: true,
    },
  });

  const { handleEvents } = useTrackingContext();
  const events = {
    onPause: () => {
      {
        firstPause &&
          handleEvents<VideoEventProps>(sendInteractionVideo, {
            component: `${component}`,
            microComponent: `${microComponent}`,
            interactionType: 'pause_video',
            attribute,
            homePosition,
          });
      }
      setFirstPause(false);
      onPause?.();
    },
    onPlay: () => {
      {
        firstPlay &&
          handleEvents<VideoEventProps>(sendInteractionVideo, {
            component: `${component}`,
            microComponent: `${microComponent}`,
            interactionType: 'play_video',
            attribute,
            homePosition,
          });
        setFirstPlay(false);

        onPlay?.();
      }
    },
    onVolumeChange: () => {
      {
        firstSoundClick &&
          handleEvents<VideoEventProps>(sendInteractionVideo, {
            component: `${component}`,
            microComponent: `${microComponent}`,
            interactionType: 'sound_video',
            attribute,
            homePosition,
          });
        setFirstSoundClick(false);
      }
    },
  };

  useEffect(() => {
    if (isVisible) {
      const $video = videoRef.current;

      if ($video) {
        $video.muted = true;
        $video.setAttribute('poster', $video.dataset.poster ?? '');
        $video
          .querySelectorAll('source')
          .forEach(($source) =>
            $source.setAttribute('src', $source.dataset.src ?? ''),
          );

        $video.load();
      }
    }
  }, [isVisible]);

  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <div ref={ref}>
      <StyledVideo
        ref={videoRef}
        data-testid="hero-video"
        muted
        playsInline
        autoPlay={autoPlay}
        loop={loop}
        controls={controls}
        data-poster={poster}
        onPlay={events?.onPlay}
        onPause={events?.onPause}
        onVolumeChange={events?.onVolumeChange}
        isSquareFormat={isSquareFormat}
      >
        {srcSet.map((src) => {
          const srcFormat = src.split('.').slice(-1)[0];
          return (
            <source
              key={src}
              data-src={src}
              type={`video/${srcFormat}`}
              data-testid="hero-video-src"
            />
          );
        })}
      </StyledVideo>
    </div>
  );
};

export default HeroVideo;
